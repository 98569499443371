import React, { useEffect, useState } from 'react';

import {
  PopupStyled,
  Overlay,
  Close,
  Content,
  CloseIconStyled,
  ImageExport,
  ImagesContainer,
  ButtonField,
  HeaderPopup
} from './Popup.style';
import { Loader } from '../Model/Model.style';
import Field from '../Field';
import Spinner from '../Spinner';

const Popup = ({
  closePopup,
  sketchfabApi,
  annotationName = 'angle_01',
  isShow,
  setImagesExport,
  imagesExport,
  imageExportSize,
  handleChangeInput
}) => {
  const [isMakingScreenshots, setIsMakingScreenshots] = useState(false);

  const handleClosePopup = () => {
    closePopup();
  };

  const getScreenshot = async ({
    annotationName = 'angle_01',
    width = imageExportSize ? imageExportSize.w : 1500,
    height = imageExportSize ? imageExportSize.h : 1500,
    mimetype = 'image/png'
  }) =>
    sketchfabApi &&
    new Promise((res, rej) => {
      if (sketchfabApi.annotations.findIndex(a => a.name === annotationName) === -1) {
        rej('Wrong annotation name.');
      }
      sketchfabApi.lookat(
        sketchfabApi._getAnnotationByName(annotationName).eye,
        sketchfabApi._getAnnotationByName(annotationName).target,
        0
      );
      setTimeout(() => {
        // may be needed for insurance, but must be tested
        sketchfabApi.getScreenShot(width, height, mimetype, (err, result) => {
          if (err) {
            rej(err);
          }
          res(result);
        });
      }, 200);
    });

  const makeScreenshots = async annotations => {
    const arrayImages = [];
    for (const item of annotations) {
      const image = await getScreenshot({ annotationName: item.name });
      arrayImages.push(image);
    }
    return arrayImages;
  };

  const getScreenshots = () => {
    const annotations = sketchfabApi.annotations;
    setIsMakingScreenshots(true);
    makeScreenshots(annotations)
      .then(images => {
        setImagesExport(images);
      })
      .then(() => {
        sketchfabApi.lookat(
          sketchfabApi._getAnnotationByName(annotationName).eye,
          sketchfabApi._getAnnotationByName(annotationName).target,
          0
        );
        setIsMakingScreenshots(false);
      });
  };

  useEffect(() => {
    // Скриншоты для изменения позиции телефона
    if (sketchfabApi && isShow) {
      getScreenshots();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sketchfabApi, isShow]);

  return (
    <PopupStyled show={isShow}>
      <Overlay onClick={handleClosePopup} />
      <Content>
        <Close onClick={handleClosePopup}>
          <CloseIconStyled />
        </Close>
        <HeaderPopup>
          <Field label="Screenshots Width" setChangeInput={e => handleChangeInput(e)} name="w" />
          <Field label="Screenshots Height" setChangeInput={e => handleChangeInput(e)} name="h" />
          <ButtonField onClick={sketchfabApi && getScreenshots}>Get screenshots</ButtonField>
        </HeaderPopup>
        <ImagesContainer>
          {imagesExport.map(base64 => (
            <ImageExport src={base64} />
          ))}
          {isMakingScreenshots && (
            <Loader>
              <Spinner />
            </Loader>
          )}
        </ImagesContainer>
      </Content>
    </PopupStyled>
  );
};

export default Popup;
