import React, { useEffect } from 'react';
import { useSketchfab } from './Model.hooks';

import { hexToLiner } from '../../helpers/colorToLiner';
import { types, typeColorCase, colorCase, textureCase } from '../CaseType/CaseType.mock';
import { sourceMaterials, materials } from '../ColorTypePhone/ColorTypePhone.mock';

import { modelUid, modelOptions } from 'consts/sketchfab';

import Spinner from '../Spinner';

import { Wrapper, Loader } from './Model.style';

const Model = ({
  caseType,
  colorTypePhone,
  colorType,
  // setImagesArray,
  // imagesPreview,
  // annotationName,
  setSketchfabApi
}) => {
  const iframeId = 'sweater';

  const [isLoading, sketchfabApi] = useSketchfab({
    uid: modelUid,
    iframeId,
    options: modelOptions
  });

  const isNotEmpty = arr => arr !== undefined && arr.length > 0 && arr[0];

  // Типы телефона
  useEffect(() => {
    if (sketchfabApi) {
      types.forEach(type => sketchfabApi.hideNode(type));
      sketchfabApi.showNode(caseType);
    }
  }, [caseType, sketchfabApi]);

  // // Смена аннотации
  // useEffect(() => {
  //   if (sketchfabApi) {
  //     sketchfabApi.lookat(
  //       sketchfabApi._getAnnotationByName(annotationName).eye,
  //       sketchfabApi._getAnnotationByName(annotationName).target,
  //       1
  //     );
  //   }
  // }, [annotationName, sketchfabApi]);

  // Цвет телефона
  useEffect(() => {
    if (sketchfabApi) {
      materials.forEach(material => {
        const targetMatArray = sketchfabApi.materials.filter(m => m.name === material);
        const sourceMatArray = sketchfabApi.materials.filter(m => m.name === sourceMaterials[colorTypePhone][material]);
        const targetMat = isNotEmpty(targetMatArray);
        const sourceMat = isNotEmpty(sourceMatArray);

        sketchfabApi.setMaterial({ ...targetMat, channels: sourceMat.channels });
      });
    }
  }, [colorTypePhone, sketchfabApi]);

  // Цвет чехла
  useEffect(() => {
    if (sketchfabApi) {
      const materialArr = sketchfabApi.materials.filter(m => m.name === typeColorCase[caseType]);
      const staticTextureIndex = sketchfabApi.textures.findIndex(m => m.name === 'neon_sand.jpg');

      const materialTransparent = sketchfabApi.materials.filter(m => m.name === 'transparent_case')[0];
      const material = isNotEmpty(materialArr);

      if (material) {
        if (typeColorCase[caseType] === 'transparent_case') {
          material.channels.Opacity.factor = colorCase[colorType];
          sketchfabApi.setMaterial(material);
        } else if (caseType === 'case_leather') {
          const src = sketchfabApi.materials.filter(m => m.name === colorType)[0];
          material.channels = src ? src.channels : material.channels;
          sketchfabApi.setMaterial(material);
        } else {
          material.channels.Opacity.factor = 0.1;
          if (colorCase[colorType] && colorCase[colorType].indexOf('#') !== -1) {
            material.channels.AlbedoPBR.color = hexToLiner(colorCase[colorType]);
          }
          sketchfabApi.setMaterial(material);
        }
      } else {
        materialTransparent.channels.Opacity.factor = 0.1;
        sketchfabApi.setMaterial(materialTransparent);
      }

      if (staticTextureIndex !== -1) {
        const currentTextureUid = sketchfabApi.textures[staticTextureIndex].uid;
        sketchfabApi.updateTexture(textureCase[colorType], currentTextureUid);

        // sketchfabApi.addVideoTexture(textureCase[colorType], true, (err, textureUid) => {
        //   const videoCaseMaterial = sketchfabApi.materials.filter(m => m.name === 'video_case')[0];
        //   console.log(textureCase[colorType], textureUid);
        //   videoCaseMaterial.channels.EmitColor.texture.uid = textureUid;

        //   sketchfabApi.setMaterial(videoCaseMaterial);
        // });
      }
    }
  }, [colorType, caseType, sketchfabApi]);

  // const getScreenshot = async ({ annotationName = 'angle_01', width = 100, height = 100, mimetype = 'image/png' }) =>
  //   sketchfabApi &&
  //   new Promise((res, rej) => {
  //     if (sketchfabApi.annotations.findIndex(a => a.name === annotationName) === -1) {
  //       rej('Wrong annotation name.');
  //     }
  //     sketchfabApi.lookat(
  //       sketchfabApi._getAnnotationByName(annotationName).eye,
  //       sketchfabApi._getAnnotationByName(annotationName).target,
  //       0
  //     );
  //     setTimeout(() => {
  //       // may be needed for insurance, but must be tested
  //       sketchfabApi.getScreenShot(width, height, mimetype, (err, result) => {
  //         if (err) {
  //           rej(err);
  //         }
  //         res(result);
  //       });
  //     }, 50);
  //   });

  useEffect(() => {
    // Скриншоты для изменения позиции телефона
    if (sketchfabApi) {
      setSketchfabApi(sketchfabApi);

      // const annotations = sketchfabApi.annotations;
      // const makeScreenshots = async annotations => {
      //   const arrayImages = [];
      //   for (const item of annotations) {
      //     const image = await getScreenshot({ annotationName: item.name });
      //     arrayImages.push({ image, annotationName: item.name });
      //   }
      //   return arrayImages;
      // };

      // makeScreenshots(annotations).then(images => {
      //   sketchfabApi.lookat(
      //     sketchfabApi._getAnnotationByName(annotationName).eye,
      //     sketchfabApi._getAnnotationByName(annotationName).target,
      //     0,
      //     setImagesArray(images)
      //   );
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sketchfabApi]);

  if (sketchfabApi) {
    sketchfabApi.addEventListener(
      'click',
      function(event) {
        // eslint-disable-next-line no-console
        console.log(event.material);
      },
      { pick: 'slow' }
    );
  }

  const isLoadingModel = isLoading;
  // const isLoadingModel = isLoading || imagesPreview.length === 0;

  return (
    <Wrapper isLoading={isLoadingModel}>
      {isLoadingModel && (
        <Loader>
          <Spinner />
        </Loader>
      )}
      <iframe title="Sketchfab Model" id={iframeId} />
    </Wrapper>
  );
};

export default Model;
