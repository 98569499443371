import Glitter from './images/glitter.jpg';
import MainImg from './images/main.jpg';
import SandNeon from './images/sand-neon.jpg';

export const buttons = [
  {
    id: 'impact',
    label: 'Impact Case',
    image: MainImg,
    price: 40,
    type: 'case_impact',
    colors: ['white', 'black']
  },
  {
    id: 'slim',
    label: 'Slim Case',
    image: MainImg,
    price: 40,
    type: 'case_slim',
    colors: ['white']
  },
  {
    id: 'sand-neon',
    label: 'Sand Neon Case',
    image: SandNeon,
    price: 30,
    type: 'case_glitter',
    colors: ['yellow_orange', 'pink_purple', 'green_teal']
  },
  {
    id: 'glitter',
    label: 'Glitter Case',
    image: Glitter,
    price: 40,
    type: 'case_glitter',
    colors: ['sparkling_blue', 'bling_gold', 'shimmering_silver']
  },
  {
    id: 'glass',
    label: 'Glass Case',
    image: MainImg,
    price: 24,
    type: 'case_glass',
    colors: []
  },
  {
    id: 'leather',
    label: 'Leather Case',
    image: MainImg,
    price: 54,
    type: 'case_leather',
    colors: [
      'leather_caramel_brown',
      'leather_agean_blue',
      'leather_onyx_black',
      'leather_ruby_red',
      'leather_yellow',
      'leather_green',
      'leather_red',
      'leather_white'
    ]
  }
];

export const types = ['case_impact', 'case_slim', 'case_glitter', 'case_glass', 'case_leather'];

export const typeColorCase = {
  case_impact: 'transparent_case',
  case_leather: 'leather_case',
  case_slim: '',
  case_glitter: 'video_case',
  case_glass: ''
};

export const colorName = {
  white: 'White',
  black: 'Black',
  leather_caramel_brown: 'Caramel Brown',
  leather_agean_blue: 'Agean Blue',
  leather_onyx_black: 'Onyx Black',
  leather_ruby_red: 'Ruby Red',
  leather_yellow: ' Yellow',
  leather_green: 'Green',
  leather_red: 'Red',
  leather_white: 'White',
  sparkling_blue: 'Sparkling Blue',
  electric_pink: 'Electric Pink',
  bling_gold: 'Bling Gold',
  shimmering_silver: 'Shimmering Silver',
  yellow_orange: 'Yellow Orange',
  pink_purple: 'Pink Purple',
  green_teal: 'Green Teal'
};

export const colorCase = {
  white: 0.1,
  black: 0.9,
  leather_caramel_brown: '#c75500',
  leather_agean_blue: '#28282f',
  leather_onyx_black: '#242424',
  leather_ruby_red: '#b22d2d',
  leather_yellow: '#ffff00',
  leather_green: '#008000',
  leather_red: '#ff0000',
  leather_white: '#ffffff'
};

export const textureCase = {
  sparkling_blue: 'https://tapmod.studio/static-files/casemyway/glitter_blue.webm',
  bling_gold: 'https://tapmod.studio/static-files/casemyway/glitter_gold.webm',
  shimmering_silver: 'https://tapmod.studio/static-files/casemyway/glitter_silver.webm',
  yellow_orange: 'https://tapmod.studio/static-files/casemyway/neon_orange.webm',
  pink_purple: 'https://tapmod.studio/static-files/casemyway/neon_purple.webm',
  green_teal: 'https://tapmod.studio/static-files/casemyway/neon_green.webm'
};
