import styled from 'styled-components';

export const Label = styled.label``;

export const Text = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
  color: black;
`;

export const Input = styled.input`
  &[type='number'] {
    height: 50px;
    width: 300px;
    font-size: 14px;
    max-width: 80%;

    &:placeholder {
      color: gray;
    }
  }
`;
