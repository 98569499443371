export const modelOptions = {
  camera: 0,
  autostart: 0,
  autospin: 0,
  preload: 1,
  annotations_visible: 0,
  animation_autoplay: 1,
  ui_infos: 0,
  ui_annotations: 0,
  ui_animations: 0,
  ui_controls: 0,
  ui_stop: 0,
  ui_help: 0,
  ui_hint: 0,
  ui_general_controls: 0,
  ui_watermark: 0,
  transparent: 0,
  scrollwheel: 1,
  double_click: 1
};

export const modelUid = 'bd1995fa8b5b4413a074ae9d34d1af40';
