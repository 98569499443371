import styled, { css } from 'styled-components';
import CloseIcon from './close.svg';

export const PopupStyled = styled.div`
  position: fixed;
  z-index: -100000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  font-family: 'Open Sans', sans-serif;
  font-size: 0;
  opacity: 0;
  transition: z-index 0s 0.25s, visibility 0s 0.25s, opacity 0.25s;
  will-change: visibility, opacity;

  ${p =>
    p.show &&
    css`
      z-index: 10000;
      visibility: visible;
      opacity: 1;
      transition: z-index 0s 0s, visibility 0s 0s, opacity 0.25s;
    `}
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: black;
  border: none;
  opacity: 0.5;
`;

export const Content = styled.div`
  position: relative;
  z-index: 10002;
  padding: 24px;
  max-width: 960px;
  width: 85vw;
  min-height: 400px;
  max-height: 90vh;
  background-color: #f9f9f9;
  border: 1px solid #edeeee;
  box-shadow: 2px 2px 12px rgba(82, 89, 118, 0.08);
  border-radius: 4px;
  overflow: hidden;
`;

export const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
`;

export const CloseIconStyled = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${CloseIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export const ImagesContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-height: 70vh;
  overflow: auto;
  margin-top: 24px;
`;

export const ImageExport = styled.img`
  margin: 10px;
  display: block;
  max-width: 43%;
  max-height: 400px;
  width: auto;
  height: auto;
`;

export const HeaderPopup = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;

  & > *:not(:last-child) {
    margin-right: 20px;
  }
`;

export const ButtonField = styled.button`
  padding: 0;
  height: 50px;
  width: 200px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  background-color: black;
  border: 1px solid transparent;
  transition: border 0.3s, background-color 0.3s, color 0.3s;
  margin: 0 auto;

  &:hover {
    background-color: #fff;
    border: 1px solid black;
    color: black;
  }
`;
