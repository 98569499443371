import styled from 'styled-components';
import ColorTypePhone from '../ColorTypePhone';
import CameraImg from './camera.png';
import RotateImg from './rotate.png';

export const ProductStyled = styled.div`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media (min-width: 1230px) {
    max-width: 1180px;
  }

  @media (max-width: 1450px) {
    max-width: 960px;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Wrap = styled.div`
  position: relative;
  width: 100%;
  min-height: 600px;

  @media (max-width: 768px) {
    min-height: 400px;
  }
`;

export const ColorTypePhoneStyled = styled(ColorTypePhone)`
  position: absolute;
  z-index: 2;
  top: 50%;
  /* left: 96px; */
  transform: translateY(-50%);

  @media (max-width: 768px) {
    left: 0;
  }
`;

export const Button = styled.button`
  /* position: absolute; */
  height: 40px;
  width: 40px;
  /* border-radius: 7px; */
  z-index: 2;
  /* bottom: 60px; */
  cursor: pointer;
  background-position: center;
  /* transform: translateX(-50%); */
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
  border: 0;
  background-size: 80%;
  background-repeat: no-repeat;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  &:hover {
    opacity: 1;
  }
`;

export const ButtonRotate = styled(Button)`
  background-image: url(${RotateImg});

  /* @media (min-width: 768px) {
    left: calc(50% - 100px);
  }

  @media (min-width: 1451px) {
    left: calc(50% - 204px);
  }

  @media (max-width: 768px) {
    left: 50%;
    bottom: 20px;
  } */
`;

export const ButtonPopup = styled(Button)`
  background-image: url(${CameraImg});
  /* 
  @media (min-width: 768px) {
    left: calc(50% - 188px);
  }

  @media (min-width: 1451px) {
    left: calc(50% - 204px);
  }

  @media (max-width: 768px) {
    left: 50%;
    bottom: 20px;
  } */
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: space-evenly;
  @media (max-width: 768px) {
    position: absolute;
    z-index: 2;
    top: 50%;
    right: calc(200% - 100vw);
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(-50%);
  }
  /* 
  @media (min-width: 768px) {
    left: calc(50% - 188px);
  }

  @media (min-width: 1451px) {
    left: calc(50% - 204px);
  }

  @media (max-width: 768px) {
    left: 50%;
    bottom: 20px;
  } */
`;
