import React, { useState } from 'react';

import { buttons } from './CaseType.mock';

import { CaseTypeStyled, Label, List, Item, Button, Image, Price, ButtonDropdown } from './CaseType.style';

const CaseType = ({ isMobile, setCaseType, className, price }) => {
  const [caseId, setCaseId] = useState(buttons[0].id);
  const [isOpen, setOpen] = useState(false);

  const handleClick = id => () => {
    const type = buttons.filter(item => item.id === id)[0].type;
    setCaseId(id);
    setCaseType(type, id);
    isMobile && setOpen(false);
  };

  const renderButton = button => (
    <Item key={button.id} active={button.id === caseId}>
      <Button onClick={handleClick(button.id)}>
        <Image src={button.image} />
        <Price>${button.price} USD</Price>
      </Button>
    </Item>
  );
  const handleOpenDropdown = () => {
    setOpen(!isOpen);
  };

  const label = buttons.filter(({ id }) => id === caseId)[0].label;

  return (
    <CaseTypeStyled className={className} isMobile={isMobile} isOpen={isOpen}>
      {isMobile ? (
        <ButtonDropdown type="button" onClick={handleOpenDropdown}>
          {label} - ${price} USD
        </ButtonDropdown>
      ) : (
        <Label>Choose your case type: {label}</Label>
      )}
      <List>{buttons.map(renderButton)}</List>
    </CaseTypeStyled>
  );
};

export default CaseType;
