import React, { useState } from 'react';
import Information from '../Information';
import Model from '../Model';
// import CameraPosition from '../CameraPosition';
import Popup from '../Popup';

import { types, buttons } from '../CaseType/CaseType.mock';
import { colors } from '../ColorTypePhone/ColorTypePhone.mock';

import { ProductStyled, Wrap, ColorTypePhoneStyled, ButtonPopup, ButtonRotate, ButtonsWrapper } from './Product.style';

const Product = props => {
  const [caseType, setCaseType] = useState(types[0]);
  const [colorType, setColorType] = useState(buttons[0].colors[0]);
  const [colorTypePhone, setColorTypePhone] = useState(colors[0]);
  const [colorsArray, setColorsArray] = useState(buttons[0].colors);
  // const [imagesPreview, setImages] = useState([]);
  const [imagesExport, setImagesExport] = useState([]);
  const [imageExportSize, setImageExportSize] = useState({ h: 500, w: 500 });
  // const [annotationName, setAnnotationName] = useState('angle_01');
  const [isShowPopup, setPopup] = useState(false);
  const [sketchfabApi, setSketchfabApi] = useState(null);

  const handleSetCaseType = (type, id) => {
    const colors = buttons.filter(button => button.id === id)[0].colors;
    const colorsDefault = colors && buttons.filter(item => item.id === id)[0].colors[0];
    setColorsArray(colors);
    setColorType(colorsDefault);
    setCaseType(type);
  };

  const handleSetColorType = type => setColorType(type);

  const handleSetColorTypePhone = color => setColorTypePhone(color);

  // const handleSetImages = images => setImages(images);

  // const handleSetAnnotationName = annotationName => setAnnotationName(annotationName);

  const handleClickRotate = () => {
    sketchfabApi.goToNextAnnotation();
  };

  const handleClickPopup = () => {
    setPopup(true);
  };

  const handleClosePopup = () => {
    setPopup(false);
  };

  const handleChangeInput = e => {
    setImageExportSize({
      ...imageExportSize,
      [e.target.name]: e.target.value
    });
  };

  const ButtonsBlock = () => (
    <ButtonsWrapper>
      <ButtonPopup onClick={handleClickPopup} />
      <ButtonRotate onClick={handleClickRotate} />
    </ButtonsWrapper>
  );

  return (
    <ProductStyled>
      {/* <CameraPosition imagesPreview={imagesPreview} setAnnotation={handleSetAnnotationName} /> */}
      <Wrap>
        {sketchfabApi && (
          //  imagesPreview.length > 0 &&
          <ColorTypePhoneStyled setColorTypePhone={handleSetColorTypePhone}>
            <ButtonsBlock />
          </ColorTypePhoneStyled>
        )}
        <Model
          caseType={caseType}
          colorType={colorType}
          colorTypePhone={colorTypePhone}
          // setImagesArray={handleSetImages}
          // imagesPreview={imagesPreview}
          // annotationName={annotationName}
          setSketchfabApi={setSketchfabApi}
        />
        {/* {sketchfabApi && (
          // imagesPreview.length > 0 &&
        )}
        {sketchfabApi && (
          // imagesPreview.length > 0 &&
        )} */}
      </Wrap>
      <Information
        setCaseType={handleSetCaseType}
        setColorType={handleSetColorType}
        colorType={colorType}
        colorsArray={colorsArray}
      />
      <Popup
        closePopup={handleClosePopup}
        isShow={isShowPopup}
        sketchfabApi={sketchfabApi}
        imagesExport={imagesExport}
        setImagesExport={setImagesExport}
        imageExportSize={imageExportSize}
        handleChangeInput={handleChangeInput}
      />
    </ProductStyled>
  );
};

export default Product;
