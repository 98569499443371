import styled, { css } from 'styled-components';
import BluePink from './textures/blue-pink.jpg';
import GreenYellow from './textures/green-yellow.jpg';
import YellowOrange from './textures/yellow-orange.jpg';
import BlingGold from './textures/gold-chrome.jpg';
import ShimmeringSilver from './textures/monochrome-silver.jpg';
import SparklingBlue from './textures/rose-pink.jpg';

export const ColorTypeStyled = styled.div`
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const Label = styled.p`
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const List = styled.ul`
  padding-left: 0;
  display: flex;
  align-items: center;
  list-style-type: none;
`;

export const Item = styled.li`
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #333;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }

  ${p =>
    p.active &&
    css`
      &::after {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

const colorsButton = {
  white: css`
    background-color: white;
  `,
  black: css`
    background-color: black;
  `,
  leather_caramel_brown: css`
    background-image: url(${require('./textures/leather_caramel_brown.png')});
  `,
  leather_agean_blue: css`
    background-image: url(${require('./textures/leather_agean_blue.png')});
  `,
  leather_onyx_black: css`
    background-image: url(${require('./textures/leather_onyx_black.png')});
  `,
  leather_ruby_red: css`
    background-image: url(${require('./textures/leather_ruby_red.png')});
  `,
  leather_yellow: css`
    background-image: url(${require('./textures/leather_yellow.png')});
  `,
  leather_red: css`
    background-image: url(${require('./textures/leather_red.png')});
  `,
  leather_green: css`
    background-image: url(${require('./textures/leather_green.png')});
  `,
  leather_white: css`
    background-image: url(${require('./textures/leather_white.png')});
  `,
  yellow_orange: css`
    background-image: url(${YellowOrange});
  `,
  pink_purple: css`
    background-image: url(${BluePink});
  `,
  green_teal: css`
    background-image: url(${GreenYellow});
  `,
  sparkling_blue: css`
    background-image: url(${SparklingBlue});
  `,
  bling_gold: css`
    background-image: url(${BlingGold});
  `,
  shimmering_silver: css`
    background-image: url(${ShimmeringSilver});
  `
};

export const Button = styled.button`
  position: relative;
  z-index: 1;
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  background-color: orange;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #e1e1e1;

  ${p => colorsButton[p.color]};
`;
