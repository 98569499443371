import styled, { css, keyframes } from 'styled-components';

export const SpinnerStyled = styled.div`
  position: relative;
  width: 25px;
  height: 25px;
`;

const styleCircle = css`
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const spiner = keyframes`
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
`;

const spiner2 = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const CircleOne = styled.div`
  ${styleCircle};

  z-index: 2;
  border: 4px dashed rgba(255, 255, 255, 0.4);
  transform: rotate(360deg);
  animation: ${spiner} 3s 0s linear infinite;
`;

export const CircleTwo = styled.div`
  ${styleCircle};

  z-index: 1;
  border-top: 4px solid rgba(51, 51, 51, 0.2);
  border-right: 4px solid rgba(51, 51, 51, 0.5);
  border-bottom: 4px solid rgba(51, 51, 51, 0.8);
  border-left: 4px solid #333;
  transform: rotate(0deg);
  animation: ${spiner2} 0.8s 0s linear infinite;
`;
