import React from 'react';
import { colorName } from '../CaseType/CaseType.mock';

import { ColorTypeStyled, Label, List, Item, Button } from './ColorType.style';

const ColorType = ({ colors, colorType, ...props }) => {
  const handleClick = colorName => () => {
    props.setColorType(colorName);
  };

  const renderColorButton = (color, key) => (
    <Item key={key} active={color === colorType}>
      <Button color={color} onClick={handleClick(color)} />
    </Item>
  );

  const label = colors.filter(color => color === colorType)[0];

  return (
    <ColorTypeStyled>
      <Label>Choose your color: {colorName[label]}</Label>
      <List>{colors.map(renderColorButton)}</List>
    </ColorTypeStyled>
  );
};

export default ColorType;
