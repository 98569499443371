import styled from 'styled-components';

const modelSize = '600px';

export const Wrapper = styled.div`
  position: relative;
  height: ${modelSize};
  float: right;
  background: #f5f5f5;
  iframe {
    border: none;
    height: 100%;
    width: 100%;
    /* z-index: ${({ isLoading }) => (isLoading ? -1 : 0)}; */
  }

  @media (min-width: 768px) {
    width: 130%;
  }

  @media (min-width: 1451px) {
    width: 135%;
  }

  @media (max-width: 768px) {
    float: none;
    width: 100%;
    height: 400px;
  }
`;

export const Loader = styled.h1`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: ${modelSize};
  text-align: center;
  transform: translate(-50%, -50%);
  margin: 0;
`;
