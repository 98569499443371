export const colors = ['silver', 'green', 'gray', 'gold'];

export const materials = ['rubber', 'metal_edge', 'iphone_back', 'camera_block'];

export const sourceMaterials = {
  silver: {
    iphone_back: 'iPhone_back_silver',
    metal_edge: 'iPhone_edge_silver',
    rubber: 'iPhone_rubber_silver',
    camera_block: 'iPhone_camera_silver'
  },
  gray: {
    iphone_back: 'iPhone_back_grey',
    metal_edge: 'iPhone_edge_grey',
    rubber: 'iPhone_rubber_grey',
    camera_block: 'iPhone_camera_grey'
  },
  green: {
    iphone_back: 'iPhone_back_green',
    metal_edge: 'iPhone_edge_green',
    rubber: 'iPhone_rubber_green',
    camera_block: 'iPhone_camera_green'
  },
  gold: {
    iphone_back: 'iPhone_back_gold',
    metal_edge: 'iPhone_edge_gold',
    rubber: 'iPhone_rubber_gold',
    camera_block: 'iPhone_camera_gold'
  }
};
