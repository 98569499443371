import styled, { css } from 'styled-components';
import ArrowIcon from './images/arrow.svg';

export const CaseTypeStyled = styled.div`
  margin-bottom: 20px;

  ${p =>
    p.isMobile &&
    css`
      @media (max-width: 768px) {
        position: relative;
        border: 1px solid #eaeaea;
        border-radius: 2px;
      }
    `}

  ${p =>
    p.isOpen &&
    css`
      @media (max-width: 768px) {
        ${List} {
          display: flex;
        }

        ${ButtonDropdown}::before {
          transform: translateY(-50%) rotate(0deg);
        }
      }
    `}
`;

export const Label = styled.p``;

export const List = styled.ul`
  padding-left: 0;
  margin-top: -3px;
  margin-left: -3px;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;

  @media (max-width: 768px) {
    display: none;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Item = styled.li`
  padding: 5px;
  padding-right: 10px;
  margin-left: 3px;
  margin-top: 3px;
  height: 56px;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  transition: border-color 0.3s;

  @media (min-width: 768px) {
    width: calc(50% - 3px);
  }

  @media (min-width: 1451px) {
    width: calc(33.333% - 6px);
  }

  @media (max-width: 768px) {
    width: calc(50% - 3px);
  }

  &:hover {
    border-color: #333;
  }

  ${props =>
    props.active &&
    css`
      border-color: #333;
    `}
`;

export const Button = styled.button`
  padding: 0;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  width: 100%;
  height: 100%;
  outline: none;
`;

export const Image = styled.img`
  width: 44px;
  height: 44px;
`;

export const Price = styled.div`
  color: #666;
  white-space: nowrap;
`;

export const ButtonDropdown = styled.button`
  position: relative;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 46px;
  border: none;
  background-color: transparent;
  text-align: left;
  outline: none;

  &::before {
    position: absolute;
    top: 50%;
    right: 18px;
    width: 12px;
    height: 12px;
    content: '';
    pointer-events: none;
    background-image: url(${ArrowIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: translateY(-50%) rotate(180deg);
    transition: transform 0.3s;
  }
`;
