import React, { useState } from 'react';
import ColorType from '../ColorType';

import { buttons } from '../CaseType/CaseType.mock';

import {
  InformationStyled,
  Title,
  InfoPrice,
  Price,
  PriceName,
  Reviews,
  Stars,
  StarIcon,
  Review,
  CaseTypeDesktop,
  CaseTypeMobile,
  AddToCart
} from './Information.style';

const Information = props => {
  const stars = Array.from(Array(5).keys());
  const [caseId, setCaseId] = useState(buttons[0].id);

  const handleCaseType = (type, id) => {
    setCaseId(id);
    props.setCaseType(type, id);
  };

  const price = buttons.filter(item => item.id === caseId)[0].price;

  return (
    <InformationStyled>
      <Title>Your Pattern</Title>
      <InfoPrice>
        <Price>${price} USD</Price>
        <PriceName>&nbsp;- Free Shipping</PriceName>
      </InfoPrice>
      <Reviews>
        <Stars>
          {stars.map((item, key) => (
            <StarIcon key={key} />
          ))}
        </Stars>
        <Review>204 Reviews</Review>
      </Reviews>
      <CaseTypeDesktop setCaseType={handleCaseType} />
      {props.colorsArray.length > 0 && (
        <ColorType setColorType={props.setColorType} colors={props.colorsArray} colorType={props.colorType} />
      )}
      <CaseTypeMobile setCaseType={handleCaseType} isMobile price={price} />
      <AddToCart type="button">Add To Cart</AddToCart>
    </InformationStyled>
  );
};

export default Information;
