import React, { useState } from 'react';

import { colors } from './ColorTypePhone.mock';

import { ColorTypePhoneStyled, List, Item, Button } from './ColorTypePhone.style';

const ColorTypePhone = props => {
  const [colorType, setColorTypePhone] = useState(colors[0]);

  const handleClick = color => () => {
    setColorTypePhone(color);
    props.setColorTypePhone(color);
  };

  const renderColorButton = (color, key) => (
    <Item key={key} active={color === colorType}>
      <Button material={color} onClick={handleClick(color)} />
    </Item>
  );

  return (
    <ColorTypePhoneStyled className={props.className}>
      <List>{colors.map(renderColorButton)}</List>
      {props.children}
    </ColorTypePhoneStyled>
  );
};

export default ColorTypePhone;
