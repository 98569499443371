import React from 'react';

import { Label, Text, Input } from './Field.style';

const Field = props => {
  const handleChange = e => {
    if (props.setChangeInput) props.setChangeInput(e);
  };

  return (
    <Label>
      <Text>{props.label}</Text>
      <Input
        type={props.type || 'number'}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue || '500'}
        onChange={handleChange}
        name={props.name}
      />
    </Label>
  );
};

export default Field;
