import React from 'react';

import { SpinnerStyled, CircleOne, CircleTwo } from './Spinner.style';

const Spinner = props => {
  return (
    <SpinnerStyled>
      <CircleOne />
      <CircleTwo />
    </SpinnerStyled>
  );
};

export default Spinner;
