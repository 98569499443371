import styled from 'styled-components';
import { ReactComponent as StarIconStyled } from './star.svg';
import CaseType from '../CaseType';

export const InformationStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);

  @media (min-width: 768px) {
    max-width: 320px;
  }

  @media (min-width: 1451px) {
    max-width: 450px;
  }

  @media (max-width: 768px) {
    position: static;
    margin-left: 0;
    margin-top: 20px;
  }
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const InfoPrice = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Price = styled.span`
  font-weight: bold;

  @media (max-width: 768px) {
    font-weight: 400;
  }
`;

export const PriceName = styled.span`
  color: #aaa;
`;

export const Reviews = styled.div`
  margin-bottom: 28px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Stars = styled.div`
  display: flex;
  align-items: center;
`;

export const StarIcon = styled(StarIconStyled)`
  width: 23px;
  height: 25px;
`;

export const Review = styled.div`
  margin-left: 10px;
  color: #aaa;
`;

export const CaseTypeDesktop = styled(CaseType)`
  @media (max-width: 768px) {
    display: none;
  }
`;
export const CaseTypeMobile = styled(CaseType)`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const AddToCart = styled.button`
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  line-height: 48px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  color: #fff;
  background-color: #333;
  border-color: #262626;
  border-radius: 2px;
  transition: background 0.2s ease-out;

  &:hover {
    background-color: #1a1a1a;
  }
`;
