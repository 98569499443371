import styled, { css } from 'styled-components';
import ColorSprite from './color.png';

export const ColorTypePhoneStyled = styled.div``;

export const List = styled.ul`
  padding-left: 0;
  list-style-type: none;
`;

export const Item = styled.li`
  position: relative;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;

  /* &:not(:last-child) {
    margin-right: 8px;
  } */

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #333;
    transform: translate(-50%, -50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }

  ${p =>
    p.active &&
    css`
      &::after {
        opacity: 1;
        visibility: visible;
      }
    `}
`;

const colorsButton = {
  gray: css`
    background-position: 0 -592px;
  `,
  green: css`
    background-position: 0 -470px;
  `,
  silver: css`
    background-position: -61px -628px;
  `,
  gold: css`
    background-position: 0 -652px;
  `
};

export const Button = styled.button`
  position: relative;
  z-index: 1;
  padding: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  outline: none;
  cursor: pointer;
  border: 1px solid #e1e1e1;
  background-image: url(${ColorSprite});
  ${p => colorsButton[p.material]};
`;
